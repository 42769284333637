
.blog-page {
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 12px 24px;

    @media (max-width: 1159px){
        max-width: 768px;
    }
    @media (max-width: 767px){
        max-width: 396px;
    }
    h1:not(.empty-title) {
        font-size: 30px;
        margin-bottom: 16px;
        font-weight: 600;

        @media (max-width: 767px) {
            font-size: 30px;
            text-align: center;
            width: 100%;
        }
    }
    .pagination {
        display: flex;
        justify-content: flex-end;
        list-style: none;
        width: 100%;
        padding: 0 22px;

        @media (max-width: 767px){
            padding: 0;
        }
        .page-item {
            margin: 0 5px;

            &.disabled {
                opacity: .5;
                pointer-events: none;
            }
        }
        .page-link {
            font-weight: bold;
            padding: 4px 7px;
            display: block;
            text-align: center;
            background: #f6f6f7;
            color: #4a4a4a;
            border-radius: 4px;
            width: 30px;
            height: 30px;
            border: none;
            line-height: 1.5;
            margin-left: 0;
            box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
            font-size: 14px;
            white-space: nowrap;

            &:hover {
                text-decoration: none;
                color: #4a4a4a;
                box-shadow: none;
                background-color: #d4d4d4;
            }
        }
    }
    .pagination .page-item.active .page-link {
        background: #444960;
        color: #ffffff;
        box-shadow: none;
    }
}
.blog-page.w-100{
    min-height: calc(100vh - 356px);
}
.blog-page-static{
    padding: 35px 24px;
    margin: 0 auto;
    max-width: 1128px;

    @media (max-width: 1159px){
        max-width: 768px;
    }
    @media (max-width: 767px){
        padding: 40px 20px;
        max-width: 420px;

        .blog-block{
            padding: 0;
            margin-bottom: 25px;
        }
    }
}
.blog-head{

    @media (min-width: 768px) {
        margin-bottom: 40px;
        padding: 12px 0;
    }
}

.blog-head-left{
    max-width: 700px;
    width: calc(100% - 500px);
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1159px) {
        width: 100%;
        max-width: inherit;
        margin: 0 auto 25px;
    }

    a {
        width: 100%;

        &:hover {
            text-decoration: none;

            .blog-head-text {
                box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
            }
        }
    }

    img{
        width: 100%;
        height: 35vw;
        max-height: 280px;
        object-fit: cover;
        object-position: center;
        border-radius: 6px 6px 0 0;

        @media (max-width: 1159px){
            height: 51vw;
            max-height: 420px;
        }
        @media (max-width: 767px){
            max-height: 220px;
            height: 52vw;
        }
    }

}
.blog-head-text {
    padding: 9px 30px 15px 30px;
    border-radius: 0 0 6px 6px;
    background-color: #F6F6F7;
    color: #000;
    transition: all 0.5s;

    @media (max-width: 767px){
        padding: 15px 15px 10px 15px;
        box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
    }
}
.blog-head-title {
    font-size: 1.4em;
    margin-top: 0;
    margin-bottom: 9px;
    line-height: 1.4;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 20px;
        -webkit-line-clamp: 3;
    }
}


.blog-head-right{
    width: 100%;
    max-width: 465px;

    @media (max-width: 1159px) {
        width: 100%;
        max-width: inherit;
    }
}
.blog-popular-item {
    color: #000000;
    margin-bottom: 38px;
    border-radius: 6px;
    transition: all .3s;

    @media (max-width: 767px){
        flex-direction: column;
        border-radius: 6px;
        margin-bottom: 25px;
    }
    @media (min-width: 768px){
        &:last-child{
            margin-bottom: 0;
        }
    }

    &:hover {
        text-decoration: none;
        color: #000000;
        box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
    }
    img {
        width: 172px;
        height: 107.5px;
        object-fit: cover;
        border-radius: 6px 0 0 6px;

        @media (max-width: 767px){
            width: 100%;
            max-height: 220px;
            height: 52vw;
        }
    }
}

.blog-popular-item-text {
    width: calc(100% - 172px);
    background-color: #F6F6F7;
    padding: 10px 15px 5px 15px;
    display: flex;
    flex-direction: column;
    border-radius: 0 6px 6px 0;

    @media (max-width: 767px) {
        padding: 10px 15px 10px 15px;
        width: 100%;
        border-radius: 0 0 6px 6px;
        box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
    }
    h2 {
        font-size: 16px;
        line-height: 1.3;
        overflow: hidden;
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;

        @media (max-width: 767px) {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
    span {
        font-size: 14px;
        margin-top: auto;
    }
}


.blog-container{
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;

    @media (max-width: 767px){
        margin: 0;
    }
}
.blog-block {
    width: 33.333333%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    padding: 12px;
    border-radius: 6px;
    position: relative;

    &:hover {
        text-decoration: none;
        color: black;

        .blog-text{
            background-color: #FAFAFA;
            box-shadow: 0 1px 1px rgb(0 0 0 / 16%);
        }
    }

    @media (max-width: 1159px){
        width: 50%;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 12px 0;
    }
}

.blog-block img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 6px 6px 0 0;

    @media (max-width: 767px) {
        max-height: 230px;
        height: 58vw;
    }
}
.blog-text {
    padding: 15px 20px 10px 20px;
    height: 150px;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 6px 6px;
    transition: all 0.3s;
    background-color: #fff;
    color: black;
    box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
    text-align: left;

    @media (max-width: 767px){
        padding: 15px 15px 10px 15px;
        height: auto;
    }
}

.blog-block h2 {
    font-size: 20px;
    margin: 0 0 10px 0;
    line-height: 1.4;
    max-height: 140px;
    overflow: hidden;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 20px;
    }
}


.blog-single{
    max-width: 1024px;
    margin: 0 auto;
    padding: 30px 20px 0;
}
.blog-single-img{
    border-radius: 6px;
    max-height: 60vh;
    object-fit: cover;
}

.blog-single-container{
    max-width: 740px;
    padding: 20px 0 100px;
    margin: 10px auto 0;
    font-size: 20px;

    @media (max-width: 767px){
        font-size: 18px;
        margin-top: 0;
    }
    h1{
        font-size: 30px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 25px;

        @media (max-width: 767px){
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    p{
        margin-bottom: 1.5em;
    }
}

.blog-author{
    margin-bottom: 60px;
    margin-top: 50px;
    font-size: 16px;

    @media (max-width: 767px) {
        margin-bottom: 50px;
    }

    img{
        width: 50px;
        height: 50px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 15px;
    }
    p{
        line-height: 1.5;
        margin-bottom: 0;
    }
    figcaption{

        @media (max-width: 767px) {
            font-size: 14px;
        }
        span{
            color: rgba(0,0,0,.4);
        }
    }
}
.blog_card_image[src=""], .blog_card_image:not([src]) {
    opacity: 0;
}
