
@import 'home';
@import '../../global/tenants-global';


:root {
    --nav-background: #ffffff;
    --nav-link: #131313;
    --nav-link-hover: #0169EE;


    --btn1-background-color: #0169EE;
    --btn1-background-hover-color: #004eb1;
    --btn1-text-color: #ffffff;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #0169EE;
    --btn1-outline-text-color: #004eb1;
    --btn1-outline-text-color-hover: #ffffff;
    --btn1-outline-border-color: #0169EE;
    --btn1-outline-border-hover-color: #0169EE;


    --btn2-background-color: #F0AE00;
    --btn2-background-hover-color: #cd9600;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #F0AE00;
    --btn2-outline-text-color: #000000;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #F0AE00;
    --btn2-outline-border-hover-color: #F0AE00;


    --color-1: #0169EE;
    --color-2: #F0AE00;


    --course-background: #ffffff;
    --course-background-hover: #fafafa;
    --course-color: #1D1D1D;
    --course-color-hover: #1D1D1D;
    --course-color-hr: #e4e8f2;
    --course-color-hr-hover: #e4e8f2;
    --course-border: transparent;
    --course-radius: 16px;
}



.basic-info-header{
    min-height: 35.7vw;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        flex-direction: column;
        min-height: auto;
    }
}
.basic-info-header-text{
    padding: 3vw 20vw 3vw 32.5vw;
    position: relative;
    z-index: 1;
    font-size: clamp(10px, 1.172vw, 18px);

    @media (max-width: 767px){
        position: static;
        font-size: 16px;
        padding: 8vw 20px 6vw 20px;
    }
    h1{
        font-weight: 400;
        line-height: 1.4;
        font-size: 2.5em;
        margin-bottom: .55em;

        @media (max-width: 767px){
            line-height: 1.2;
            margin-bottom: 0;
            position: absolute;
            right: 16px;
            top: 13vw;
            width: 54%;
            font-size: 5.5vw;
        }
    }
    > p{
        letter-spacing: .03em;
        line-height: 1.55;
        font-size: 1.125em;
        margin-bottom: 1.8em;

        @media (max-width: 767px){
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 1.2em;
            letter-spacing: 0;
        }
    }
    > .btn{
        font-size: 1em;
    }
}
.basic-info-header-img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;

    @media (max-width: 767px){
        height: 45vw;
        object-position: left;
        position: static;
    }
}


.landing-header{
    padding-left: 24px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;

    @media (min-width: 2000px) {
        justify-content: center;
    }
    @media (max-width: 767px){
        flex-wrap: wrap;
        padding: 36px 24px;
        margin: 0 auto;
        max-width: 420px;
    }
}
.landing-header-text{
    width: 48.4%;
    padding-right: 24px;
    max-width: 660px;

    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        max-width: inherit;
        position: relative;
        z-index: 1;
        padding: 0;
    }
    h1{
        font-weight: 400;
        margin-bottom: 0.6em;
        font-size: 2.8em;
        line-height: 1.22;

        @media (max-width: 1199px){
            font-size: 2em;
        }
        @media (max-width: 991px){
            font-size: 1.4em;
        }
        @media (max-width: 767px){
            font-size: 24px;

            &:lang(hy){
                font-weight: 600;
            }
        }
    }
    > p{
        margin-bottom: 2.3em;
        font-size: 1.185em;
        line-height: 1.5;

        @media (max-width: 1199px){
            font-size: 14px;
            margin-bottom: 1.3em;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
    > .btn{
        font-size: 1.11em;

        @media (max-width: 1199px){
            font-size: 1em;
        }
        @media (max-width: 991px){
            font-size: .9em;
        }
    }
}
.landing-header-img{
    width: 50%;
    padding: 2.5% 3.5%;

    @media (max-width: 767px){
        width: 100%;
        max-width: 660px;
        margin: 0 auto;
        position: absolute;
        z-index: 0;
        opacity: .3;
        padding: 0;
        left: 0;
        top: 0;
        height: 100%;
    }
    @media (min-width: 2000px){
        max-width: 1000px;
    }
    > img{
        width: 100%;
        object-fit: cover;
        height: auto;

        @media (max-width: 767px){
            height: 100%;
        }
    }
}
.landing-head-bg{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
