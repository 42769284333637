@import 'bootstrap';
@import 'variables';
@import 'courses';
@import 'quizzes';
@import 'blog';
@import 'contact';
@import 'faq';
@import 'live-lessons';
@import 'slicks';
@import 'toastr';
@import 'rtl';


[dir="rtl"]{
    display: none;
}
.btn[disabled]{
    opacity: .4;
}
body .row{

    .cell{
        min-height: 40px;
    }
}
video{
    max-width: 100%;
}
#app{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
}
.coming-soon{
    height: 100vh;

    h1{
        font-size: 25px;
        font-weight: normal;
        margin-top: 30px;
    }
}
.coming-soon-text{
    padding-top: 22vh;
    padding-left: 14vh;
    max-width: 50%;

    @media (max-width: 767px) {
        padding-top: 9vh;
        padding-left: 4vh;
        max-width: 95%;
    }
}
.coming-soon-bg{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
}
.overdue{
    height: 100vh;

    @media (max-width: 767px) {
        text-align: center;
    }

    h1{
        font-size: 20px;
        font-weight: normal;
        margin-top: 22px;
        margin-bottom: 33px;
    }
}
.overdue-text{
    padding-top: 22vh;
    padding-left: 14vh;
    max-width: 660px;
    background-color: #ffffffc9;
    box-shadow: 0 0 45px #fff;
    border-radius: 5px;

    @media (max-width: 767px) {
        padding-top: 3vh;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0 auto;
    }
}
.overdue-bg{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;

    @media (max-width: 767px) {
        display: none;
    }
}
.paypal-section{
    min-height: calc(100vh - 312px);

    @media (max-width: 767px){
        flex-direction: column-reverse;
        min-height: auto;
    }
}
.paypal-section-left{
    width: 32%;
    padding: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px){
        width: 100%;
        padding: 40px 20px;
    }
    h1{
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
    }
    #paypal-button-container{
        position: sticky;
        top: 30px;
    }
}
.paypal-section-right{
    background-color: #F4F5F8;
    width: 68%;
    padding: 2vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px){
        width: 100%;
        padding: 20px;
    }
}
.paypal-course-item{
    max-width: 523px;
    color: black;
    padding: 28px;
    background-color: #ffffff;
    margin-bottom: 15px;

    @media (max-width: 991px) {
        padding: 15px;
    }
    @media (max-width: 550px){
        flex-direction: column;
    }
    &:hover{
        color: black;
        text-decoration: none;
    }

    > img{
        width: 134px;
        height: 102px;
        object-fit: cover;

        @media (max-width: 550px){
            margin: 0 auto 10px;
        }
    }
}
.paypal-course-item-info{
    width: calc(100% - 134px);
    padding-left: 20px;

    @media (max-width: 550px){
        width: 100%;
        padding-left: 0;
    }
    h2{
        font-size: 16px;
        font-weight: bold;
        line-height: 1.35;
    }
    table{
        font-size: 14px;
        width: 100%;
        margin-bottom: 10px;

        td{

            &:first-child{
                white-space: nowrap;
            }
            &:last-child{
                text-align: right;
                padding-left: 15px;
                font-size: 16px;
            }
        }
    }
}
.paypal-course-item-price{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #DEDEDE;
    padding-top: 7px;

    b{
        font-size: 18px;
    }
}
.pay-thankyou{
    background-color: #F4F5F8;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1{
        font-size: 22px;
        text-align: center;
        margin: 18px 0 42px;
        font-weight: 700;
        color: #0E0E0E;
        line-height: 1.35;
    }
}
.pay-thankyou-courses{
    padding: 60px 10px;
}
.toast-success{
    background-color: var(--btn1-background-color);
    color: var(--btn1-text-color);
}
.page-404{
    height: 100vh;
    padding: 30px 0;
}
.text-404{
    padding-left: 5vw;
    width: 49%;

    @media (min-width: 1500px) {
        padding-left: 10vw;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 0 20px;
    }
    h1{
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 20px;

        @media (min-width: 1500px){
            font-size: 40px;
        }
    }
    p{
        font-size: 18px;
        margin-bottom: 40px;
    }
}
.img-404{
    width: 51%;

    @media (max-width: 767px) {
        width: 100%;
    }
}


.home-course-section{
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 12px 24px;

    @media (max-width: 1159px){
        max-width: 768px;
    }
    @media (max-width: 767px){
        max-width: 396px;
    }
}
.home-course-section-container{
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;

    @media (max-width: 767px){
        justify-content: center;
        margin: 0;
    }
    .btn-1-outline{
        margin-top: 30px;
    }
}
.home-course-item-block{
    height: auto;
    padding: 12px;
    transition: color .3s;
    width: 33.333333%;

    &:hover{
        color: #1D1D1D;
        text-decoration: none;

        .home-course-author, .home-course-info-block, .home-course-price{
            background-color: var(--course-background-hover);
            color: var(--course-color-hover);
        }
        .home-course-item{
            box-shadow: 0 2px 6px #0000000f;
        }
        hr{
            border-color: var(--course-color-hr-hover);
        }
    }
    hr{
        margin: 0;
        transition: all .3s;
    }
    @media (max-width: 1159px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        max-width: 348px;
        padding: 12px 0 12px;
    }
}
.home-course-item {
    width: 100%;
    color: #1D1D1D;
    box-shadow: 0 2px 6px #00000029;
    display: block;
    border-radius: var(--course-radius);
    position: relative;
    transition: all .3s;
}
.home-course-item-img{
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: var(--course-radius) var(--course-radius) 0 0;
}
.home-course-author{
    background-color: var(--course-background);
    color: var(--course-color);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-top: solid 1px var(--course-border);
    border-left: solid 1px var(--course-border);
    border-right: solid 1px var(--course-border);
    transition: background-color .3s;

    img{
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 8px;
        border-radius: 50%;
    }
    span{
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
    }
}
.home-course-info-block{
    background-color: var(--course-background);
    color: var(--course-color);
    padding: 10px 20px;
    height: 170px;
    border-left: solid 1px var(--course-border);
    border-right: solid 1px var(--course-border);
    transition: background-color .3s;

    h2{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    p{
        font-size: 14px;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0;
    }
}
.home-course-price{
    background-color: var(--course-background);
    color: var(--course-color);
    transition: background-color .3s;
    padding: 10px 20px;
    line-height: 1.2;
    height: 48px;
    border-radius: 0 0 var(--course-radius) var(--course-radius) ;
    border-left: solid 1px var(--course-border);
    border-right: solid 1px var(--course-border);
    border-bottom: solid 1px var(--course-border);

    p{
        font-size: 14px;
        padding-right: 10px;

        @media (max-width: 358px){
            font-size: 12px;
        }
    }
    span{
        font-size: 20px;
        font-weight: 700;

        @media (max-width: 400px){
            font-size: 18px;
        }
        @media (max-width: 358px){
            font-size: 16px;
        }
        del{
            font-size: 16px;
            opacity: .8;
            margin-right: 12px;
            display: inline-block;

            @media (max-width: 400px){
                font-size: 14px;
            }
            @media (max-width: 358px){
                margin-right: 7px;
                margin-bottom: 0;
            }
        }
    }
}
.home-course-section.home-course-section-slider{

    .home-course-item{
        text-align: left;
    }
}
.home-course-section-container.slick-slider{

    @media (max-width: 767px){
        max-width: 410px;
        margin: 0 -6px;
    }

    .slick-prev, .slick-next{
        width: 24px;
        height: 30px;
        opacity: 1;
        border-radius: 50%;
        color: inherit;
        z-index: 1;

        &:before{
            border: solid black;
            border-width: 0 2px 2px 0;
            content: "";
            display: inline-block;
            padding: 6px;
            border-radius: 0;
            background-color: transparent;
            color: inherit;
        }
    }
    .slick-next {
        right: -19px;

        @media (min-width: 1280px){
            right: -20px;
        }
        @media (max-width: 830px) {
            right: -11px;
        }
        @media (max-width: 767px) {
            right: -16px;
        }
        &:before{
            margin: 0 4px 0 0;
            transform: rotate(-45deg);
            border-color: inherit;
            border-width: 0 2px 2px 0;
            padding: 6px;
        }
    }
    .slick-prev {
        left: -19px;

        @media (min-width: 1280px){
            left: -20px;
        }
        @media (max-width: 830px) {
            left: -11px;
        }
        @media (max-width: 767px) {
            left: -16px;
        }
        &:before{
            margin: 0 0 0 4px;
            transform: rotate(135deg);
            border-color: inherit;
            border-width: 0 2px 2px 0;
            padding: 6px;
        }
    }
    .slick-slide{
        height: auto;
    }

    .home-course-item-block{

        @media (max-width: 767px){
            padding: 12px 6px 12px;
            max-width: inherit;
        }
    }
}

.global-form{
    padding: 24px 24px;
    max-width: 1140px;
    margin: 0 auto;

    @media (max-width: 1159px){
        max-width: 768px;
    }
    form{
        margin-bottom: 0;
    }
}

.background-landing {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    h2{
        padding: 0 24px;
        text-align: center;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            font-size: 26px;
        }
    }
}
.edit-page-builder {
    position: fixed;
    top: 100px;
    right: 50px;
    z-index: 99999;
}
.cookie-section {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 98%;
    max-width: 1186px;
    margin: 0 auto;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    background: #fff;
    z-index: 8;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media (max-width: 991px) {
    .cookie-section {
        padding: 15px 20px;
    }
}
@media (max-width: 767px) {
    .cookie-section {
        flex-wrap: wrap;
        padding-top: 40px;
    }
}
.cookie-section > img {
    margin-right: 20px;
}
@media (max-width: 767px) {
    .cookie-section > img {
        display: none;
    }
}
@media (max-width: 991px) {
    .cookie-section p {
        font-size: 14px;
        line-height: 1.4;
    }
}
@media (max-width: 767px) {
    .cookie-section p {
        margin-bottom: 10px;
    }
}
.cookie-section .btn {
    border-radius: 6px;
    border: 1px solid #c4c4d2;
    margin-left: 12px;
    white-space: nowrap;
    font-size: 14px;
    padding: 9px 17px;
    color: #000000;
    margin-bottom: 8px;
}
.cookie-section .btn:hover {
    background-color: #f1f1f1;
}
.cookie-section .btn-close {
    padding: 3px 10px;
    border: none;
    background-color: #ffffff;
    margin-left: 25px;
    transition: transform 0.3s;
    position: absolute;
    top: -30px;
    right: 15px;
    border-radius: 4px 4px 0 0;
}
.cookie-section .btn-close:hover {
    transform: scale(1.2);
}
@media (max-width: 991px) {
    .edit-page-builder {
        display: none;
    }
}



.products-page-title{

    @media (max-width: 767px){
        text-align: center;
    }
}
.products-container-wrapper {
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 12px 24px;

    @media (max-width: 1159px){
        max-width: 768px;
    }
    @media (max-width: 767px){
        max-width: 430px;
    }
    .products-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;
        margin-right: -12px;

        @media (max-width: 767px){
            margin: 0;
        }
        .oneItem {
            padding: 12px;
            width: 50%;
            position: relative;

            @media (max-width: 1159px){
                width: 100%;
            }
            @media (max-width: 767px){
                padding: 12px 0;
            }
            .imageBox {
                width: 198px;
                height: 350px;
                flex: 0 0 198px;
                background: #F4F5F8 0 0 no-repeat padding-box;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 767px) {
                    width: 100%;
                    height: 380px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            > div{
                border: solid 1px #e9e9e9;
            }
        }

        .detailsBox {
            background: #FFFFFF 0 0 no-repeat padding-box;
            color: #000000;
            padding: 15px;
            flex-grow: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            text-align: left;

            .title {
                font-size: 20px;
                color: #000000;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .description {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .priceBox {
                .price {
                    font-size: 20px;
                    line-height: 30px;
                    color: #000000;
                }

                .old_price {
                    font-size: 20px;
                    line-height: 30px;
                    color: #FF7F7F;
                }
            }

            .footer {
                margin-top: auto;
            }
        }
    }
}
.products-container-wrapper-static{
    max-width: 1140px;
    padding: 10px 12px 50px;
    margin: 0 auto;

    @media (max-width: 1159px){
        max-width: 768px;
    }
    @media (max-width: 767px){
        padding: 10px 20px 50px;
        max-width: 450px;

        .products-container .oneItem{
            padding: 10px 0 10px;
        }
    }
    .products-container{
        margin: 0;
    }
}
.product-container-wrapper {
    max-width: 1120px;
    margin: 0 auto;
    padding: 40px 20px 40px;

    @media (max-width: 1159px){
        max-width: 770px;
    }
    @media (max-width: 767px){
        max-width: 420px;

        .products-container .oneItem{
            padding: 10px 0 10px;
        }
    }
    .products-container-wrapper{
        max-width: inherit;
    }
    .product-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 80px;
    }

    .imageBox {
        width: 423px;

        @media (max-width: 1159px){
            width: 320px;
        }
        @media (max-width: 767px){
            width: 100%;
            margin-bottom: 30px;
        }
        .top {
            border: 1px solid #D6D7D9;
            border-radius: 3px;

            .sliderItem {
                width: 353px;
                height: 529px;
                border-radius: 3px;
                padding: 10px;

                @media (max-width: 1157px){
                    height: 390px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .bottom {
            max-width: 350px;
            margin: 0 auto;
            width: 100%;

            .sliderItem {
                width: 63px;
                height: 94px;
                border: 1px solid #D6D7D9;
                border-radius: 3px;
                margin: 8px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .slick-prev, .slick-next{
                width: 32px;
                height: 32px;
                opacity: 1;
                border-radius: 50%;
                color: inherit;
                z-index: 1;

                &:before{
                    border: solid black;
                    border-width: 0 2px 2px 0;
                    content: "";
                    display: inline-block;
                    padding: 6px;
                    border-radius: 0;
                    background-color: transparent;
                    color: inherit;
                }
            }
            .slick-next {
                right: -22px;

                @media (min-width: 1160px){
                    right: -34px;
                }
                &:before{
                    margin: 0 4px 0 0;
                    transform: rotate(-45deg);
                    border-color: inherit;
                    border-width: 0 2px 2px 0;
                    padding: 6px;
                }
            }
            .slick-prev {
                left: -22px;

                @media (min-width: 1160px){
                    left: -34px;
                }
                &:before{
                    margin: 0 0 0 4px;
                    transform: rotate(135deg);
                    border-color: inherit;
                    border-width: 0 2px 2px 0;
                    padding: 6px;
                }
            }
        }
    }

    .detailsBox {
        width: calc(100% - 423px);
        padding-left: 24px;

        @media (max-width: 1159px){
            width: calc(100% - 320px);
        }
        @media (max-width: 767px){
            width: 100%;
            padding-left: 0;
        }
        .title {
            text-align: left;
            font-size: 30px;
            line-height: 46px;
            letter-spacing: 0;
            color: #0A0A0A;

            @media (max-width: 1159px){
                font-size: 26px;
                line-height: 36px;
            }
            @media (max-width: 767px){
                font-size: 22px;
                line-height: 30px;
            }
        }

        .author {
            text-align: left;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0;
            color: #0A0A0A;
        }

        .priceBox {
            .priceText {
                font-size: 16px;
                line-height: 26px;
                color: #888888;
            }
            .price {
                font-size: 20px;
                line-height: 30px;
                color: #000000;
            }

            .old_price {
                font-size: 20px;
                line-height: 30px;
                color: #FF7F7F;
            }
        }

        .descriptionText {
            margin: 15px 0;
            font-size: 18px;
            line-height: 27px;
        }
    }

    .video-wrapper {
        width: 100%;
        text-align: center;
        padding: 7px 0;

        .video-btn {
            padding: 10px 18px 10px 50px;
            border: 1px solid #D6D7D9;
            border-radius: 3px;
            background-color: #F8F8F8;
            display: inline-block;
            position: relative;
            font-size: 16px;
            color: #0A0A0A;

            svg {
                position: absolute;
                left: 19px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .promo-video {
        max-width: 100%;
        width: 100%;
        height: 100%;
    }
}

.similarProducts {
    padding: 0 12px;

    h3 {
        font-size: 20px;
        margin-bottom: 25px;
    }
}
.inStock {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0;
    color: #45C9A5;

    &.unavailable {
        color: #ccc;
    }
}
.productCheckoutTitle {
    font-size: 25px;
    margin-top: 38px;
    font-weight: 600;
}
.checkout-container {
    max-width: 1280px;
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 1199px) {
        padding-top: 40px;
        max-width: 1110px;
    }
}
.borderBox {
    border-right: 2px solid #fff;
}
.btn-download {
    border: none;
    position: absolute;
    right: 7px;
    bottom: 21px;
    background-color: transparent;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    color: #414141;
    transition: all 0.2s;
    padding: 13px;
    outline: none;
    border-radius: 0 0 15px 0;
}
.full-screen-overlay{
    overflow: hidden;
}
.full-screen-editor{
    position: fixed !important;
    top:0;
    left: 0;
    right:0;
    bottom: 0;
    z-index:1000;
}
.full-screen-editor .ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.full-screen-editor .ck.ck-editor__main,
.full-screen-editor .ck.ck-editor__main .ck-source-editing-area {
    height: 100%;
    max-height: none;
}

.courses-search-form-wrapper{
    padding: 25px 12px 10px;

    @media (max-width: 767px) {
       display: block;
        padding: 20px 0;
    }
    h1{
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;

        @media (max-width: 767px){
            text-align: center;
        }
    }
}
.courses-search-form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 42px;
    height: 42px;
    overflow: hidden;
    transition: 0.2s;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    flex: 0 0 42px;
    margin-bottom: 10px;

    @media (max-width: 767px){
        margin-left: auto;
    }
}
.courses-search-form.show {
    width: 300px;
    flex: 0 0 300px;
    border-color: #c2c2c2;

    @media (max-width: 767px){
        flex: 0 0 auto;
        width: 100%;
    }
}
.courses-search-form button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 50%;
    padding: 5px;

    svg{
        width: 18px;
    }
}
.courses-search-form.show button {
    display: none;
}
.courses-search-form input {
    position: absolute;
    right: 0;
    height: 40px;
    width: 100%;
    z-index: 1;
    pointer-events: auto;
    user-select: auto;
    background-color: #fff;
    border: none;
    padding-left: 37px;
    padding-right: 30px;
}
.courses-search-form input:focus {
    outline: none;
}


.input-wrapper {
    position: relative;
    width: 0;
    height: 40px;
}
.input-wrapper svg {
    position: absolute;
    left: 5px;
    width: 25px;
    top: 6px;
    z-index: 3;
    display: none;
}
.courses-search-form.show .input-wrapper {
    width: 100%;
    display: block;
}
.courses-search-form.show .input-wrapper svg {
    display: block;
}


.home-static-container{
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    position: relative;
    padding: 12px 24px;

    @media (max-width: 1159px){
        max-width: 768px;
    }
}
.home-static-container-2{
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    padding: 12px 24px;
    position: relative;
}
.home-static-container-3{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    padding: 12px 24px;
    position: relative;
}
.landing-container{
    max-width: 1140px;
    padding: 24px 24px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        max-width: 420px;
    }
}
.landing-container-reverse{

    @media (max-width: 767px){
        flex-direction: column;
    }
    .landing-right{
        padding: 12px 24px 12px 0;

        @media (max-width: 767px){
            padding: 0;
        }
    }
}
.theme-all-form{
    background-color: black;
    color: #ffffff;
    padding: 60px 0;

    @media (max-width: 767px){
        padding: 36px 0;
    }
}
.theme-all-form-bg{
    position: absolute;
    z-index: 0;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
}
.theme-all-form-container{
    max-width: 768px;
    margin: 0 auto;
    padding: 12px 24px;
    position: relative;
    z-index: 1;

    > p{
        color: inherit;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 36px;

        @media (max-width: 767px){
            font-size: 16px;
            margin-bottom: 24px;
            line-height: 22px;
        }
    }
    form{
        max-width: 450px;
        margin: 0 auto;

        .form-control{
            background-color: transparent;
            border: none;
            border-radius: 0;
            padding-left: 6px;
            color: inherit;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: inherit;

            &.is-invalid{
                border-color: #dc3545;
            }
            &::placeholder{
                opacity: 1;
                color: inherit;
            }
            &:focus{
                box-shadow: none;
            }
        }
        textarea.form-control{
            resize: none;
            height: 80px;
        }
    }
}


.landing-static{
    padding: 30px 0;

    @media (max-width: 991px){
        padding: 10px 0;
    }
    @media (max-width: 767px){
        margin: 0 auto;
        max-width: 420px;
    }
    &:last-child{
        margin-bottom: 40px;
    }
}
.landing-left{
    width: 46%;
    max-width: 500px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: 556px;
    }
}
.landing-img{
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}
.landing-right{
    width: 54%;
    max-width: 525px;
    padding: 12px 0 12px 24px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
        padding: 0;
        margin-bottom: 20px;
    }
    > h2{
        font-size: 30px;
        font-weight: 400;
        margin-bottom: .9em;

        @media (max-width: 991px) {
            font-size: 22px;
        }
        @media (max-width: 767px) {
            font-size: 20px;
        }

        &:lang(hy){
            font-weight: 600;

            @media (min-width: 1160px){
                font-size: 28px;
            }
        }
    }
    > p{
        font-size: 17px;
        line-height: 1.5;
        margin-bottom: 2em;

        @media (max-width: 991px) {
            font-size: 14px;
            line-height: 1.4;
            margin-bottom: 1.5em;
        }

        &:lang(hy){

            @media (min-width: 1160px) {
                font-size: 16px;
                line-height: 1.4;
            }
        }
    }
    .btn{

        @media (max-width: 991px){
            font-size: .9em;
        }
    }
}

.landing-container-full{
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1vw;

    @media (max-width: 1159px) {
        font-size: 1.3vw;
    }
    @media (max-width: 767px){
        font-size: 10px;
    }
}
.landing-container-full-reverse{

    @media (max-width: 767px){
        flex-direction: column-reverse;
    }
}
.landing-container-full-text{
    width: 40%;
    padding: 20px 24px;
    margin: 0 auto;

    @media (max-width: 1159px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        padding: 24px;
    }
    > h2{
        font-size: 2em;
        margin-bottom: 0.8em;
    }
    > p{
        margin-bottom: 1.7em;
        font-size: 1.18em;
        line-height: 1.5;

        @media (max-width: 1159px){
            margin-bottom: 1.2em;
        }
        @media (max-width: 767px){
            font-size: 15px;
            line-height: 1.4;
        }
    }
    .btn{
        font-size: 1em;

        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}
.landing-container-full-img{
    width: 50%;

    @media (max-width: 767px){
        width: 100%;
    }
    > img{
        width: 100%;
        object-fit: cover;
        height: auto;

        @media (max-width: 767px){
            height: auto;
        }
    }
}

.theme-all-info-section-1-container{
    max-width: 730px;
    margin: 0 auto;

    h1{
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 36px;

        @media (max-width: 767px) {
            font-size: 30px;
            margin-bottom: 24px;
        }

        &:lang(hy){
            font-size: 32px;

            @media (max-width: 767px){
                font-size: 26px;
            }
        }
    }
    > p{
        font-size: 18px;

        @media (max-width: 767px) {
            font-size: 16px;
        }

        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
.theme-all-info-section-2-container{
    max-width: 770px;
    margin: 0 auto;
    padding: 68px 20px 72px;

    @media (max-width: 767px){
        padding: 40px 20px 40px;
    }
    > h2, > h1{
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 36px;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 24px;
        }

        &:lang(hy){
            font-size: 32px;

            @media (max-width: 767px){
                font-size: 22px;
            }
        }
    }
    > p{
        font-size: 18px;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    iframe{
        margin-bottom: 20px;
        margin-top: 20px;
        max-width: 100%;
        height: 50vw;
        max-height: 332px;
    }
}


.pm-10{
    padding: 12px 0;
}
.p-10{
    padding: 10px 0;
}
[id^="i"]:not([data-gjs-type]):not([class]){
    max-width: 100%;

    .gjs-lory-frame{
        max-width: 100%;
    }
 }
.gjs-lory-slides{
    max-width: 100%;
}
.gjs-lory-frame{
    padding: 10px 0;
    margin: 10px auto!important;
    max-width: 100%;
}
.gjs-lory-slide{
    max-width: 100%;
    margin-right: 0!important;
}
[data-accordions], [data-tabs]{
    padding: 24px 24px;
    width: 100%;

    .accordion{
        margin: 0 0 6px 0;
        color: inherit;
        background-color: inherit;
        font-weight: 600;
        position: relative;
        font-size: 18px;
        padding: 7px 34px 7px 0;

        @media (max-width: 767px) {
            font-size: 17px;
        }
        &:hover{
            text-decoration: none;
        }

        svg{
            position: absolute;
            right: 0;
            top: 10px;
            opacity: .7;
        }
    }
    .accordion-content{
        margin-bottom: 5px;
        border: none;
        opacity: .8;
        padding: 7px 0;
        font-size: 18px;
        min-height: 60px;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    }
    .tab{

        &:hover{
            text-decoration: none;
        }
    }

    .accordion-active{

        .accordion{

            svg{
                path:nth-child(2){
                    display: none;
                }
            }
        }
    }
}


.basic-section-3-container, .basic-section-4-container-1, .basic-section-5-container-1, .home-static-container, .home-static-container-2,
.home-static-container-3, .theme-4-service{

    .home-course-section, .live-section, .products-container-wrapper, .blog-page{
        padding: 0;
    }
    .landing-container{
        padding: 24px 0;
    }
    .landing-container-full{
        font-size: 14px;
        max-width: 1092px;
        margin: 0 auto;
    }
    .landing-container-full-text{
        width: 50%;
        padding-left: 0;
    }
    .landing-container-full-reverse{

        .landing-container-full-text{
            padding-right: 0;
            padding-left: 24px;
        }
    }
    .global-form{
        padding: 12px 0;
        margin: 0 auto;
    }
    [data-accordions], [data-tabs]{
        padding: 24px 0;
    }
    > .row{
        padding: 10px 0;
        margin-left: -10px;
        margin-right: -10px;
    }
    iframe{
        width: 100%;
    }
    .theme-all-form-container{
        padding: 24px 0;
    }
    @media (min-width: 768px) and (max-width: 1159px){

        .landing-right{

            > h2{
                font-size: 24px;
                margin-bottom: 0.7em;
            }
            > p{
                font-size: 14px;
                margin-bottom: 1.5em;
            }
            .btn{
                font-size: 0.9em;
            }
        }
        .landing-container-full{
            max-width: 720px;
            font-size: 12px;
        }
    }
    @media (max-width: 767px){

        .landing-container{
            padding: 12px 0;
        }
        .landing-container-full{
            max-width: 400px;
            font-size: 10px;
        }
        .landing-container-full-text, .landing-container-full-reverse .landing-container-full-text{
            width: 100%;
            padding: 24px 0;
        }
        .home-course-section, .blog-page{
            max-width: 348px;
        }
        .live-section .first-live-container.slick-slider{
            padding: 0;
            margin: 0 -6px;
        }
        .live-section.live-section-slider{
            max-width: 520px;
        }
        .live-section .first-live-container.slick-slider .slick-next{
            right: -15px;
        }
        .live-section .first-live-container.slick-slider .slick-prev{
            left: -15px;
        }
    }
}
.home-static-container-2, .home-static-container-3{

    .home-course-section, .live-section, .products-container-wrapper, .blog-page, .landing-container{
        max-width: 1092px;

        @media (max-width: 1159px){
            max-width: 720px;
        }
        @media (max-width: 767px){
            max-width: 400px;
        }
    }
}

.products-container-wrapper, .global-form, .blog-page, .live-section, .home-course-section,
.theme-8-special-content, .theme-8-people-left, .basic-section-2-right, .theme-7-header-text, .home-studies-left{

    .products-container-wrapper, .global-form, .blog-page, .live-section, .home-course-section{
        padding: 0;
        margin: 0 auto;
    }
    [data-accordions], [data-tabs]{
        padding: 24px 0;
        margin: 0 auto;
    }
    .row{
        padding: 10px 0;
        margin-left: -10px;
        margin-right: -10px;

        .row{
            padding: 0;
            margin: 0;
        }
    }
    .landing-container{
        padding: 24px 0;
    }
    .landing-container-full{
        font-size: 14px;
        max-width: 1092px;
        margin: 0 auto;
    }
    .landing-container-full-text{
        width: 50%;
        padding-left: 0;
    }
    .landing-container-full-reverse{

        .landing-container-full-text{
            padding-right: 0;
            padding-left: 24px;
        }
    }
    .theme-all-form-container{
        padding: 24px 0;
    }
    @media (max-width: 767px){
        .landing-container{
            padding: 12px 0;
        }
        .landing-container-full{
            max-width: 400px;
            font-size: 10px;
        }
        .landing-container-full-text, .landing-container-full-reverse .landing-container-full-text{
            width: 100%;
            padding: 24px 0;
        }
    }
}

.membership-section{
    padding: 60px 12px;

    @media (max-width: 470px){
        padding: 48px 3px;
    }
}
.membership-container{

    h1{
        font-weight: 600;
        font-size: 35px;
        margin-bottom: 24px;

        @media (max-width: 470px){
            font-size: 30px;
            margin-bottom: 20px;
        }
    }
}
.membership-item-tenant{
    width: 33.33%;
    padding: 12px;
    max-width: 470px;
    min-width: 441px;

    @media (max-width: 470px) {
        width: 100%;
        min-width: inherit;
    }
}
.membership-item-tenant-head{
    background: var(--color-1);
    color: #ffffff;
    padding: 12px 24px;
    border-radius: 6px 6px 0 0;

    @media (max-width: 470px){
        padding: 10px 15px;
    }
    h2{
        font-weight: 500;
        font-size: 25px;
        padding-right: 15px;

        @media (max-width: 470px){
            font-size: 24px;
        }
    }
    p{
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        b{
            font-weight: 600;
            font-size: 25px;

            @media (max-width: 470px){
                font-size: 24px;
            }
        }
        del{
            font-size: 16px;
            color: #dbd6d6;
            margin-right: 5px;
        }
    }
}
.membership-item-tenant-content{
    border: 1px solid #D5D5E0;
    padding: 24px 15px 30px;

    p{
        color: #7C7C7C;
        margin-bottom: 24px;
        height: 72px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    h3{
        color: #000000;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 24px;
    }
}
.membership-item-tenant-list{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;

    @media (max-width: 470px){
        flex-direction: column;
        align-items: flex-start;
        max-width: 250px;
        margin: 0 auto;
    }
    li{
        color: var(--color-1);
        display: flex;
        align-items: center;
        padding: 0 8px;
        position: relative;
        font-size: 15px;

        @media (max-width: 470px){
            font-size: 16px;
            padding: 7px;
        }
        &:before{
            content: "";
            position: absolute;
            right: 0;
            top: 7px;
            width: 1px;
            height: 12px;
            background-color: var(--color-1);

            @media (max-width: 470px){
                display: none;
            }
        }
        &:last-child{
            @media (min-width: 471px){
                padding-right: 0;
            }

            &:before{
                display: none;
            }
        }
        &:first-child{
            @media (min-width: 471px){
                padding-left: 0;
            }
        }
        svg{
            margin-right: 4px;
            flex: 0 0 19px;

            @media (max-width: 470px){
                margin-right: 7px;
            }
        }
    }
}
.membership-item-tenant-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    border: 1px solid #D5D5E0;
    border-top: none;

    @media (max-width: 470px){
        padding: 24px 12px;
    }
    .btn{
        margin-bottom: 12px;
        font-size: 18px;
    }
}
.membership-item-tenant-info{
    border-left: 1px solid #D5D5E0;
    border-right: 1px solid #D5D5E0;
    border-bottom: 1px solid #D5D5E0;
}
.membership-item-tenant-info-title{
    background: #F8F8FA;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 24px;

    @media (max-width: 470px){
        padding: 12px 15px;
    }
    span{
        color: #808081;
        font-weight: 400;
        margin-right: 10px;
    }
}
.membership-item-tenant-info-list{
    padding: 12px 24px!important;

    @media (max-width: 470px){
        padding: 12px 15px;
    }
    li{
        padding: 10px 0;

        a{
            display: flex;
            align-items: flex-start;
            color: black;
            line-height: 24px;
            padding: 5px 0;

            &:hover{
                color: var(--color-1);
                text-decoration: none;
            }
            svg{
                margin-right: 10px;
                flex: 0 0 20px;
                width: 20px;
                margin-top: 2px;
            }
        }
    }
}
